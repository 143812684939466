import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { nextStepWithChecks } from '../commonSteps';
import { redirectIfSuccess } from '../../../../utils/authTree/helpers';
import { getCallbackOfTypeSafely } from '../../../../utils/authTreeUtils';

export default async function abandon(dispatch, brand) {
  // Restart the tree
  let currentStep = await nextStepWithChecks(dispatch, null, brand);

  const choiceCallback = getCallbackOfTypeSafely(currentStep, CallbackType.ChoiceCallback);
  choiceCallback.setChoiceValue('abandon');
  currentStep = await nextStepWithChecks(dispatch, currentStep, brand);
  redirectIfSuccess(currentStep);

  return currentStep;
}
