import React from 'react';
import PropTypes from 'prop-types';
import { FormattedRichMessage } from 'ciam-self-service-shared';
import { useSelector } from 'react-redux';
import { MFA_SELECT_CHANNEL_PAGE_NAME } from './constants';
import FormElement from '../FormElement';
import MFAChannelButton from './MFAChannelButton';
import AnalyticsDataLayer from '../Analytics';

function MFASelectChannel(props) {
  const requestInProgress = useSelector((state) => state.mfa.requestInProgress);
  const { channels, onSelectChannel } = props;
  const mfaDetail = useSelector((state) => state?.pageInfo?.mfaDetail);
  return (
    <>
      <AnalyticsDataLayer pageName={MFA_SELECT_CHANNEL_PAGE_NAME} />
      {!mfaDetail && <FormattedRichMessage id="mfa.selectChannel.page.content" />}
      {channels.map((channel) => (
        <FormElement key={channel.value}>
          <MFAChannelButton
            id={channel.type}
            type={channel.type}
            maskedValue={channel.maskedValue}
            isLoading={requestInProgress[channel.type]}
            onClick={() => onSelectChannel(channel)}
          />
        </FormElement>
      ))}
    </>
  );
}

MFASelectChannel.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      maskedValue: PropTypes.number.isRequired,
    }),
  ),
  onSelectChannel: PropTypes.func.isRequired,
};

MFASelectChannel.defaultProps = {
  channels: [],
};

export default MFASelectChannel;
