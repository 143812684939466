import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  ErrorAlert,
  Form,
  FormattedRichMessage,
  UsernameInput,
  USERNAME_VARIANTS,
  useRealm,
  useCIAMHistory,
  useCIAMContextParam,
  ContentPage,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import submitUsernameAsyncAction from 'utils/forgotPasswordAuthnTree/submitUsernameAsyncAction';
import { useDispatch, useSelector } from 'react-redux';
import initAsyncAction from 'utils/forgotPasswordAuthnTree/initAsyncActionImpl';
import { updateErrorCode } from 'components/Login/actions';
import { FORGOT_PASSWORD_TREE } from 'components/Login/constants';
import useAuthTreeErrorHandler from 'utils/authTree/useAuthTreeErrorHandler';
import { useLocation } from 'react-router-dom';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import AnalyticsDataLayer from '../../common/Analytics';
import { FP_PROMPT_PAGE_NAME, EVENT_ENTER_USERNAME_ERROR } from '../constants';
import InternetExplorerUserWarning from '../../common/InternetExplorerUserWarning';
import { changePasswordUpdateStatus, updateMfaPage } from '../actions';

const ForgotPasswordPrompt = () => {
  const intl = useIntl();
  const realm = useRealm();
  const stage = useSelector((state) => state.loginInfo.stage);
  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const error = useSelector((state) => state.loginInfo.loginError);
  const brand = useCIAMContextParam('brand');
  const requestInProgress = useSelector((state) => state.forgotPasswordInfo.passwordUpdateStatus);
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const location = useLocation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateMfaPage({ mfaDetail: true }));
    const payload = {
      userName: username,
      stage,
      history,
      realm,
      brand,
    };
    try {
      dispatch(changePasswordUpdateStatus(true));
      await submitUsernameAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(changePasswordUpdateStatus(false));
    }
  };

  useEffect(() => {
    const showError = location?.state?.showError;
    initAsyncAction(dispatch, FORGOT_PASSWORD_TREE, realm, brand, showError);
  }, [realm, brand, dispatch, location]);

  useEffect(() => {
    return () => {
      // reset all errors on component unload
      dispatch(updateErrorCode(null));
    };
  }, [dispatch]);

  const handleUsernameChange = (value) => {
    setUsername(value);
    if (error?.component === 'username') {
      dispatch(updateErrorCode(null));
    }
  };

  return (
    <Page title={intl.formatMessage({ id: 'forgotPassword.prompt.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      {error && <AnalyticsDataLayer eventName={EVENT_ENTER_USERNAME_ERROR} error={error} />}
      {!error && <AnalyticsDataLayer pageName={FP_PROMPT_PAGE_NAME} />}
      <ContentPage heading={<FormattedMessage id="forgotPassword.prompt.page.heading" />}>
        <InternetExplorerUserWarning />
        <ErrorAlert error={error} />
        <FormattedRichMessage id="forgotPassword.prompt.page.content" />
        <Form onSubmit={handleSubmit}>
          <FormElement>
            <UsernameInput
              variant={USERNAME_VARIANTS.PROMPT}
              id="username"
              value={username}
              onChange={handleUsernameChange}
              errors={[error]}
              required
              ariaRequired
              autoFocus
            />
          </FormElement>
          <FormElement>
            <CoBrandingButton
              id="fp-username-continue"
              type="submit"
              customStyle={customStyles.button}
              disabled={requestInProgress}
              saving={requestInProgress}
            >
              <FormattedMessage id="forgotPassword.prompt.submit" />
            </CoBrandingButton>
          </FormElement>
        </Form>
      </ContentPage>
    </Page>
  );
};

export default ForgotPasswordPrompt;
