import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  ErrorAlert,
  Form,
  FormattedRichMessage,
  PasswordInput,
  PASSWORD_INPUT_VARIANTS,
  ContentPage,
  useCIAMHistory,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import newPasswordAsyncAction from 'utils/forgotPasswordAuthnTree/newPasswordAsyncActionImpl';
import { useSelector, useDispatch } from 'react-redux';
import { changePasswordUpdateStatus } from 'components/ForgotPassword/actions';
import useAuthTreeErrorHandler from 'utils/authTree/useAuthTreeErrorHandler';
import AllFieldsRequiredMessage from '../../common/AllFieldsRequiredMessage';
import FormElement from '../../common/FormElement';
import Page, { AUTH_TYPE } from '../../common/Page';
import { updateErrorCode } from '../../Login/actions';
import AnalyticsDataLayer from '../../common/Analytics';
import { FP_ENTER_PW_PAGE_NAME, EVENT_ENTER_PW_ERROR } from '../constants';
import useRedirectOnStageReset from '../../../hooks/useRedirectOnStageReset';
import useResetStageOnHistoryChange from '../../../hooks/useResetStageOnHistoryChange';

const ResetPasswordInputPage = () => {
  const intl = useIntl();
  const [password, setPassword] = useState('');
  const error = useSelector((state) => state.loginInfo.loginError);

  const stage = useSelector((state) => state.loginInfo.stage);
  const passwordUpdateStatus = useSelector((state) => state.forgotPasswordInfo.passwordUpdateStatus);

  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const handleChangePassword = (newValue) => {
    setPassword(newValue);
    if (error?.component === 'password') {
      dispatch(updateErrorCode(null));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      newPassword: password,
      stage,
      history,
    };
    try {
      dispatch(changePasswordUpdateStatus(true));
      await newPasswordAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(changePasswordUpdateStatus(false));
    }
  };

  useResetStageOnHistoryChange();
  useRedirectOnStageReset('D_704', '/forgotpassword');

  return (
    <Page title={intl.formatMessage({ id: 'resetPassword.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      {error && <AnalyticsDataLayer eventName={EVENT_ENTER_PW_ERROR} error={error} />}
      {!error && <AnalyticsDataLayer pageName={FP_ENTER_PW_PAGE_NAME} />}
      <ContentPage heading={<FormattedRichMessage id="resetPassword.page.heading" />}>
        <AllFieldsRequiredMessage />
        <ErrorAlert error={error} />
        <Form onSubmit={handleSubmit}>
          <FormElement>
            <PasswordInput
              id="password"
              value={password}
              onChange={handleChangePassword}
              errors={[error]}
              variant={PASSWORD_INPUT_VARIANTS.RESET}
              required
              ariaRequired
              autoFocus={true}
            />
          </FormElement>

          <FormElement>
            <CoBrandingButton
              type="submit"
              customStyle={customStyles.button}
              disabled={passwordUpdateStatus}
              saving={passwordUpdateStatus}
            >
              <FormattedMessage id="resetPassword.submitButtonLabel" />
            </CoBrandingButton>
          </FormElement>
        </Form>
      </ContentPage>
    </Page>
  );
};

export default ResetPasswordInputPage;
