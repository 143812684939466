import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { updateStageAction } from 'components/Login/actions';
import { useHistory } from 'react-router-dom';

/**
 * User-defined hook to reset the stage when the popstate is triggered.
 */
const useResetStageOnHistoryChange = () => {
  const stage = useSelector((state) => state.loginInfo.stage);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleStateChange = useCallback(() => {
    if (Object.keys(stage).length !== 0) {
      dispatch(updateStageAction({}));
    }
  }, [dispatch, stage]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        handleStateChange();
      }
    };
  }, [handleStateChange, history]);

  return handleStateChange;
};

export default useResetStageOnHistoryChange;
