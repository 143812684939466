import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useCIAMHistory } from 'ciam-self-service-shared';
import { mapErrorFromJson } from 'utils/error-map';
import { updateErrorCode } from 'components/Login/actions';

/**
 * User defined hook to handle redirection and setting error code when stage is reset.
 */
const useRedirectOnStageReset = (errorCode, redirectUrl) => {
  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const stage = useSelector((state) => state.loginInfo.stage);

  useEffect(() => {
    if (Object.keys(stage).length === 0) {
      dispatch(updateErrorCode(mapErrorFromJson(errorCode)));
      history.push(redirectUrl, { showError: true });
    }
  }, [stage, dispatch, history, errorCode, redirectUrl]);
};

export default useRedirectOnStageReset;
