import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FormattedRichMessage, ContentPage } from 'ciam-self-service-shared';
import userContextUtils from 'utils/userContextUtils';
import resendOtpAsyncAction from 'utils/loginAuthnTree/resendOtpAsyncAction';
import tryOTPAnotherWayAsyncAction from 'utils/loginAuthnTree/tryOTPAnotherWayAsyncAction';
import useAuthTreeErrorHandler from 'utils/authTree/useAuthTreeErrorHandler';
import otpAsyncAction from 'utils/loginAuthnTree/otpAsyncAction';
import { MFA_ENTER_CODE } from 'components/common/MFA/constants';
import MFAEnterCode from '../../common/MFA/MFAEnterCode';
import Page, { AUTH_TYPE } from '../../common/Page';
import AnalyticsDataLayer from '../../common/Analytics/index';
import useRedirectOnStageReset from '../../../hooks/useRedirectOnStageReset';
import useResetStageOnHistoryChange from '../../../hooks/useResetStageOnHistoryChange';

function LoginVerifyMfa() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const stage = useSelector((state) => state.loginInfo.stage);
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const otpCount = useSelector((state) => state.loginInfo.otpCount);
  const isOTPGeneratedMultipleTimes = useSelector((state) => state.loginInfo.isOTPGeneratedMultipleTimes);
  const isLoginAfterError = useSelector((state) => state.loginInfo.isLoginAfterError);
  const requestInProgress = useSelector((state) => state.forgotPasswordInfo.passwordUpdateStatus);
  let userContext = useSelector((state) => state.loginInfo.userContext);
  const loginError = useSelector((state) => state.loginInfo.loginError);
  const channels = userContextUtils.getChannels(userContext);
  let channel = userContextUtils.getSelectedChannel(userContext);
  const contextFromSession = sessionStorage.getItem('context');
  const msgPlaceHolderValue = isOTPGeneratedMultipleTimes ? intl.formatMessage({ id: `mfa.enterCode.new` }) : '';

  if (Object.keys(stage).length === 0 && !channel) {
    if (contextFromSession) {
      userContext = JSON.parse(contextFromSession);
      channel = userContextUtils.getSelectedChannel(userContext);
    }
  }

  const handleSubmitCode = async (_, payload) => {
    try {
      await otpAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  const handleRequestResend = async (_, payload) => {
    try {
      await resendOtpAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  const handleRequestNewChannel = async (_, payload) => {
    try {
      await tryOTPAnotherWayAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  useResetStageOnHistoryChange();
  useRedirectOnStageReset('D_704', '/forgotpassword');

  return (
    <Page title={intl.formatMessage({ id: 'mfa.enterCode.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <AnalyticsDataLayer pageName={MFA_ENTER_CODE} otcChannel={channel} />
      <ContentPage
        heading={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <FormattedRichMessage
            id="mfa.enterCode.page.heading"
            values={{
              new: msgPlaceHolderValue,
            }}
          />
        }
      >
        <MFAEnterCode
          channel={channel}
          onSubmitCode={handleSubmitCode}
          singleChannel={channels?.length === 1}
          stage={stage}
          onRequestResend={handleRequestResend}
          otpCount={otpCount}
          hasResentOtp={isOTPGeneratedMultipleTimes}
          onRequestNewChannel={handleRequestNewChannel}
          error={loginError}
          isLoginAfterError={isLoginAfterError}
          isLoading={requestInProgress}
        />
      </ContentPage>
    </Page>
  );
}

export default LoginVerifyMfa;
