import React, { useState } from 'react';
import Page, { AUTH_TYPE } from 'components/common/Page';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  DateOfBirth,
  ErrorAlert,
  Form,
  FormattedRichMessage,
  FormTextInput,
  validIsRequired,
  ContentPage,
  useCIAMHistory,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import FormElement from 'components/common/FormElement';
import AllFieldsRequiredMessage from 'components/common/AllFieldsRequiredMessage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector, useDispatch } from 'react-redux';
import userInfoAsyncAction from 'utils/forgotPasswordAuthnTree/userInfoAsyncActionImpl';
import { changePasswordUpdateStatus } from 'components/ForgotPassword/actions';
import useAuthTreeErrorHandler from 'utils/authTree/useAuthTreeErrorHandler';
import useDateOfBirthState from '../../../hooks/useDateOfBirthState';
import AnalyticsDataLayer from '../../common/Analytics';
import { FP_PI_PAGE_NAME, EVENT_FP_PI_ERROR } from '../constants';
import useRedirectOnStageReset from '../../../hooks/useRedirectOnStageReset';
import useResetStageOnHistoryChange from '../../../hooks/useResetStageOnHistoryChange';

dayjs.extend(utc);

const ResetPasswordConfirmPage = () => {
  const intl = useIntl();
  const [lastName, setLastName] = useState('');
  const [dateOfBirthParts, dateOfBirth, setDateOfBirth] = useDateOfBirthState();
  const error = useSelector((state) => state.forgotPasswordInfo.error);
  const stage = useSelector((state) => state.loginInfo.stage);
  const requestInProgress = useSelector((state) => state.forgotPasswordInfo.passwordUpdateStatus);
  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // TODO: Move this to redux. Formatting the date to what the backend requires shouldn't be part of the view.
    const dob = `${dayjs(dateOfBirth).format('YYYY-MM-DD')}T00:00:00Z`;
    const payload = {
      stage,
      lastName: lastName.trim(),
      dob,
      history,
    };
    try {
      dispatch(changePasswordUpdateStatus(true));
      await userInfoAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(changePasswordUpdateStatus(false));
    }
  };

  useResetStageOnHistoryChange();
  useRedirectOnStageReset('D_704', '/forgotpassword');

  return (
    <Page
      title={intl.formatMessage({ id: 'resetPassword.confirm.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'resetPassword.confirm.page.heading' })}
    >
      {error && <AnalyticsDataLayer eventName={EVENT_FP_PI_ERROR} error={error} />}
      {!error && <AnalyticsDataLayer pageName={FP_PI_PAGE_NAME} />}
      <ContentPage heading={<FormattedRichMessage id="resetPassword.confirm.page.heading" />}>
        <FormattedRichMessage id="resetPassword.confirm.page.content" />
        <ErrorAlert error={error} />

        <Form onSubmit={handleSubmit}>
          <AllFieldsRequiredMessage />

          <FormElement>
            <FormTextInput
              id="first-name"
              name="first-name"
              autoComplete="given-name"
              label={intl.formatMessage({ id: 'signup.personalInfo.lastName' })}
              value={lastName}
              onChange={setLastName}
              required
              ariaRequired
              validations={validIsRequired}
              disabled={error}
            />
          </FormElement>

          <FormElement>
            <DateOfBirth
              dateOfBirth={dateOfBirth}
              dateOfBirthParts={dateOfBirthParts}
              onChange={setDateOfBirth}
              required
              ariaRequired
              disabled={error}
            />
          </FormElement>

          <FormElement>
            <CoBrandingButton
              type="submit"
              customStyle={customStyles.button}
              disabled={requestInProgress || error}
              saving={requestInProgress}
            >
              <FormattedMessage id="resetPassword.confirm.submitButtonLabel" />
            </CoBrandingButton>
          </FormElement>
        </Form>
      </ContentPage>
    </Page>
  );
};

export default ResetPasswordConfirmPage;
