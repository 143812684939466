import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FormattedRichMessage, CIAMLinkButton, ContentPage, customStyles } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import AnalyticsDataLayer from '../../common/Analytics';
import { FP_SUCCESS_PAGE_NAME, EVENT_FP_SUCCESS } from '../constants';
import useRedirectOnStageReset from '../../../hooks/useRedirectOnStageReset';
import useResetStageOnHistoryChange from '../../../hooks/useResetStageOnHistoryChange';

function ResetPasswordDonePage() {
  const intl = useIntl();

  useResetStageOnHistoryChange();
  useRedirectOnStageReset('D_704', '/forgotpassword');

  return (
    <Page
      title={intl.formatMessage({ id: 'resetPassword.done.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'resetPassword.done.page.heading' })}
    >
      <AnalyticsDataLayer pageName={FP_SUCCESS_PAGE_NAME} />
      <AnalyticsDataLayer eventName={EVENT_FP_SUCCESS} />
      <ContentPage heading={<FormattedRichMessage id="resetPassword.done.page.heading" />}>
        <FormattedRichMessage id="resetPassword.done.page.content" />
        <FormElement>
          <CIAMLinkButton to="/" customStyle={customStyles.button}>
            <FormattedMessage id="common.signin" />
          </CIAMLinkButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
}

export default ResetPasswordDonePage;
