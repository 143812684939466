import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT } from '@manulife/mux';
import {
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
  ContentPage,
  FormattedRichMessage,
  CoBrandingButton,
  customStyles,
  useRichIntl,
} from 'ciam-self-service-shared';
import gotoMyAccountHandlerAsyncAction from 'utils/loginAuthnTree/gotoMyAccountHandlerAsyncActionImpl';
import { useDispatch, useSelector } from 'react-redux';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import useGoToUrl from '../../../hooks/useGoToUrl';
import AnalyticsDataLayer from '../../common/Analytics';
import { MOBILE_NUMBER_CONFIRMED } from '../constants';

function AddMobileFinish() {
  const intl = useRichIntl();
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const stage = useSelector((state) => state.loginInfo.stage);
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const firstName = userContext.givenName;
  const gotoURI = useGoToUrl();
  const { locale } = useIntl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');

  const gotoMyAccount = () => {
    const payload = {
      stage,
      history,
      gotoURI,
      locale,
      realm,
      brand,
    };

    gotoMyAccountHandlerAsyncAction(dispatch, payload);
  };

  return (
    <Page
      title={intl.formatMessage({ id: 'signin.addmobile.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'signin.addmobile.verify.finish.heading' })}
    >
      <AnalyticsDataLayer pageName={MOBILE_NUMBER_CONFIRMED} />
      <ContentPage
        heading={<FormattedRichMessage id="signin.addmobile.verify.finish.heading" values={{ firstName }} />}
      >
        <FormattedRichMessage id="signin.addmobile.verify.finish.text" />
        <FormElement spacing={2}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.PRIMARY}
            customStyle={customStyles.button}
            onClick={gotoMyAccount}
            id="continue-btn"
          >
            <FormattedMessage id="signin.addmobile.verify.finish.connectButton" />
          </CoBrandingButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
}

export default AddMobileFinish;
