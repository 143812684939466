import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { MESSAGE_OTP_EXPIRED, MESSAGE_OTP_RETRY_LIMIT, MESSAGE_TRY_NEW_OTP } from 'components/Login/constants';
import { getCallbackOfTypeSafely } from '../authTreeUtils';
import AuthTreeError from './AuthTreeError';

const CustomCallbackType = {
  RedirectCallback: 'RedirectCallback',
};

const TerminateSessionResponse = 'terminateSessionResponse';

function doesStepMatchMessage(step, messageToMatch) {
  const textOutputCallback = getCallbackOfTypeSafely(step, CallbackType.TextOutputCallback);
  const message = textOutputCallback?.getOutputByName('message');
  return message === messageToMatch;
}

export function extractUserContext(callback) {
  const userContextScript = callback.getOutputByName('message');
  // Start of parsing userContext out of script
  const startOfSection = userContextScript.indexOf('context');
  const endOffValue = userContextScript.indexOf('function submit');
  const value = userContextScript.substring(startOfSection + 10, endOffValue).replace(/;/g, '');
  // End of parsing userContext out of script
  return JSON.parse(value);
}

export const handleRedirectCallback = (redirectCallback, brand) => {
  let redirectUrl = redirectCallback.getOutputByName('redirectUrl');
  if (brand && isValidUrl(redirectUrl)) {
    redirectUrl = new URL(redirectUrl);
    redirectUrl.searchParams.set('brand', brand);
  }
  window.location.href = redirectUrl;
};

export function isOtpExpired(textOutputCallback) {
  const message = textOutputCallback?.getOutputByName('message');
  return message === MESSAGE_OTP_EXPIRED;
}

export function isOnEnterOtpStep(step) {
  const passwordCallback = getCallbackOfTypeSafely(step, CallbackType.PasswordCallback);
  return Boolean(passwordCallback);
}

export function isOtpRetryLimitReached(step) {
  return doesStepMatchMessage(step, MESSAGE_OTP_RETRY_LIMIT);
}

export function isNewOtpRequired(step) {
  return doesStepMatchMessage(step, MESSAGE_TRY_NEW_OTP);
}

export function isFailed(step) {
  return step?.type === StepType.LoginFailure || step === StepType.LoginFailure;
}

export function redirectIfRequired(step, brand) {
  const redirectCallback = getCallbackOfTypeSafely(step, CustomCallbackType.RedirectCallback);
  if (redirectCallback) {
    handleRedirectCallback(redirectCallback, brand);
    return true;
  }

  return false;
}

export function redirectIfSuccess(step) {
  if (step.type === StepType.LoginSuccess) {
    window.location.href = step.getSuccessUrl();
    return true;
  }
  return false;
}

export function isRedirectRequired(step) {
  const redirectCallback = getCallbackOfTypeSafely(step, CustomCallbackType.RedirectCallback);
  return Boolean(redirectCallback);
}

export function isSessionTerminated(step) {
  const hiddenValueCallback = getCallbackOfTypeSafely(step, CallbackType.HiddenValueCallback);
  const id = hiddenValueCallback?.getOutputByName('id');

  return id === TerminateSessionResponse;
}

export function checkStep(step, errorCode) {
  if (!step || isFailed(step)) {
    throw new AuthTreeError('Unexpected error', step, errorCode);
  }
  return step;
}

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
