import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { BUTTON_VARIANT } from '@manulife/mux';
import { CoBrandingButton, useRealm, useCIAMContextParam } from 'ciam-self-service-shared';
import determineCallback from 'utils/loginAuthnTree/determineCallback';
import useAuthTreeErrorHandler from '../useAuthTreeErrorHandler';
import useOnDemandOtpType from '../useOnDemandOtpType';
import onDemandAuthTree from '../authTree';

function OnDemandBackButton() {
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const dispatch = useDispatch();
  const type = useOnDemandOtpType();
  const brand = useCIAMContextParam('brand');
  const { locale } = useIntl();
  const realm = useRealm();

  const handleClick = async () => {
    try {
      if (type === 'saml') {
        const env = window.REACT_ENV;
        const redirectionURL = new URL(determineCallback(env, locale, realm));
        if (brand) {
          redirectionURL.searchParams.set('brand', brand);
        }
        window.location.replace(redirectionURL.href);
      } else {
        await onDemandAuthTree.abandon(dispatch, brand);
      }
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  return (
    <CoBrandingButton
      variant={BUTTON_VARIANT.TERTIARY_BACK}
      onClick={handleClick}
      customStyle={{ buttonStyle: { padding: 0 } }}
    >
      <FormattedMessage id="mfa.goBack" />
    </CoBrandingButton>
  );
}

export default OnDemandBackButton;
