import React, { useMemo, useCallback } from 'react';
import MFASelectChannel from 'components/common/MFA/MFASelectChannel';
import { FormattedRichMessage, ContentPage, LoadingPage, useRichIntl } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from 'components/common/Page';
import useAuthTreeErrorHandler from 'utils/authTree/useAuthTreeErrorHandler';
import { useSelector, useDispatch } from 'react-redux';
import otpChannelSelectionAsyncAction from 'utils/loginAuthnTree/otpChannelSelectionAsyncAction';
import useSelectChannel from 'components/common/MFA/useSelectChannel';
import { updateMfaButtonStatus } from '../../actions/actions';
import useRedirectOnStageReset from '../../hooks/useRedirectOnStageReset';
import useResetStageOnHistoryChange from '../../hooks/useResetStageOnHistoryChange';

function LoginMFASelectChannelPage() {
  const intl = useRichIntl();
  const dispatch = useDispatch();
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const requestInProgress = useSelector((state) => state.mfa.requestInProgress);
  const [channels, handleSelectChannel, displayChoices] = useSelectChannel(
    useCallback(
      async (_, payload, selectedChannel) => {
        try {
          requestInProgress[selectedChannel.type] = true;
          dispatch(updateMfaButtonStatus(requestInProgress));
          await otpChannelSelectionAsyncAction(dispatch, payload);
        } catch (err) {
          authTreeErrorHandler(err);
        } finally {
          requestInProgress[selectedChannel.type] = false;
          dispatch(updateMfaButtonStatus(requestInProgress));
        }
      },
      [authTreeErrorHandler, dispatch, requestInProgress],
    ),
  );
  const mfaDetail = useSelector((state) => state?.pageInfo?.mfaDetail);
  const promptContent = useMemo(() => {
    if (mfaDetail) {
      return 'forgotPasswordMfa.page.promptContent';
    }
    return 'signinMfa.page.promptContent';
  }, [mfaDetail]);

  useResetStageOnHistoryChange();
  useRedirectOnStageReset('D_704', '/forgotpassword');

  return (
    <Page
      title={intl.formatMessage({ id: 'mfa.selectChannel.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'mfa.selectChannel.page.heading' })}
    >
      {displayChoices ? (
        <ContentPage heading={<FormattedRichMessage id="mfa.selectChannel.page.heading" />}>
          <FormattedRichMessage id={promptContent} />
          <MFASelectChannel channels={channels} onSelectChannel={handleSelectChannel} />
        </ContentPage>
      ) : (
        <LoadingPage />
      )}
    </Page>
  );
}

export default LoginMFASelectChannelPage;
