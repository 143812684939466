import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateUserContext } from 'components/Login/actions';
import executeHiddenCallbackForOTPAutomatedTesting from '../../../utils/loginAuthnTree/executeHiddenCallbackForOTPAutomatedTesting';
import nextStep from '../../../utils/loginAuthnTree/handleStep';
import AuthTreeError from '../../../utils/authTree/AuthTreeError';
import { OnDemandOTPRedirectError, OnDemandOTPUnauthenticatedError } from './errors';
import {
  extractUserContext,
  isFailed,
  isOtpRetryLimitReached,
  isSessionTerminated,
  redirectIfRequired,
} from '../../../utils/authTree/helpers';
import { getCallbackOfTypeSafely } from '../../../utils/authTreeUtils';

export async function checkStep(dispatch, step, brand) {
  let currentStep = step;

  const errorButNeedsNextStep = isFailed(currentStep) || isOtpRetryLimitReached(currentStep);
  if (errorButNeedsNextStep) {
    currentStep = await nextStep(dispatch, currentStep);
  }

  if (redirectIfRequired(currentStep, brand)) {
    throw new OnDemandOTPRedirectError(currentStep);
  }

  if (isSessionTerminated(currentStep)) {
    throw new OnDemandOTPUnauthenticatedError(currentStep);
  }
}

export async function nextStepWithChecks(dispatch, step, brand) {
  let currentStep = step;

  await checkStep(dispatch, currentStep, brand);
  currentStep = await nextStep(dispatch, currentStep);
  await checkStep(dispatch, currentStep, brand);

  return currentStep;
}

export async function progressToChannelSelect(dispatch, step) {
  let currentStep = step;

  const textOutputCallback = getCallbackOfTypeSafely(currentStep, CallbackType.TextOutputCallback);
  if (textOutputCallback) {
    const userContext = extractUserContext(textOutputCallback);
    dispatch(updateUserContext(userContext));
  }

  const hiddenValueCallback = step.getCallbackOfType(CallbackType.HiddenValueCallback);
  hiddenValueCallback.setInputValue('output');
  currentStep = await nextStepWithChecks(dispatch, currentStep);

  return currentStep;
}

export async function submitOtp(dispatch, step, otpCode) {
  let currentStep = step;

  currentStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, currentStep);

  const passwordCallback = getCallbackOfTypeSafely(currentStep, CallbackType.PasswordCallback);

  await checkStep(dispatch, step);
  if (!passwordCallback) {
    throw new AuthTreeError('Unable to submit OTP');
  }

  passwordCallback.setPassword(otpCode);
  currentStep = await nextStepWithChecks(dispatch, currentStep);
  return currentStep;
}
