import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { P } from '@manulife/mux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useCIAMHistory,
  useRealm,
  usePathBuilder,
  useCIAMContextParam,
  ErrorAlert,
  Form,
  FormattedRichMessage,
  Link,
  OneTimeCode,
  StageShape,
  ErrorShape,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import FormElement from '../FormElement';
import mfaChannelShape from './mfaChannelShape';
import { updateErrorCode, updateOTPRequestedMultipleTimes, updateUserContext } from '../../Login/actions';
import useGoToUrl from '../../../hooks/useGoToUrl';
import AnalyticsDataLayer from '../Analytics';
import { EVENT_OTC_ERROR, CONFIRM_YOUR_MOBILE_NUMBER } from './constants';
import { updateMfaPage } from '../../ForgotPassword/actions';

function MFAEnterCode(props) {
  const {
    stage,
    channel,
    otpCount,
    onRequestResend,
    onRequestNewChannel,
    onSubmitCode,
    error,
    singleChannel,
    header,
    content,
    isLoading,
  } = props;
  let { hasResentOtp } = props;
  const gotoURI = useGoToUrl();
  const intl = useIntl();
  const { locale } = intl;
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const ciamHistory = useCIAMHistory();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const pathBuilder = usePathBuilder();
  const history = useHistory();

  const contextFromSession = sessionStorage.getItem('context');
  const otpMultipleTimesFlagFromStorage = sessionStorage.getItem('isOTPGeneratedMultipleTimes');
  if (otpMultipleTimesFlagFromStorage === 'true') {
    hasResentOtp = true;
  }
  useEffect(() => {
    if (Object.keys(stage).length === 0 && contextFromSession) {
      dispatch(updateUserContext(JSON.parse(contextFromSession)));
    }
    if (otpMultipleTimesFlagFromStorage === 'true') {
      dispatch(updateOTPRequestedMultipleTimes(true));
    }
  }, [contextFromSession, dispatch, otpMultipleTimesFlagFromStorage, stage]);

  useEffect(() => {
    if (error?.err?.errorCode === 'E_116') {
      setCode('');
    }
  }, [error]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
    if (error?.component === 'otp-code') {
      dispatch(updateErrorCode(null));
    }
  };

  const getContentMessageId = () => {
    if (channel?.maskedValue) {
      return 'mfa.enterCode.content.masked';
    }
    if (channel?.type === 'email') {
      return 'mfa.enterCode.content.anonymous.email';
    }
    if (channel?.type === 'phone') {
      return 'mfa.enterCode.content.anonymous.phone';
    }
    return null;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateMfaPage({ mfaDetail: false }));
    if (error?.err?.errorCode !== 'D_614') {
      onSubmitCode(dispatch, {
        stage,
        otpCount,
        otp: code,
        isOTPGeneratedMultipleTimes: hasResentOtp,
        history,
        realm,
        brand,
        gotoURI,
        locale,
        pathBuilder,
        ciamHistory,
      });
    }
  };

  const handleRequestNewChannel = () => {
    if (onRequestNewChannel) {
      onRequestNewChannel(dispatch, { stage, history, pathBuilder, ciamHistory, realm, brand });
    }
  };

  const handleRequestResend = () => {
    onRequestResend(dispatch, {
      stage,
      otpCount, // TODO: Remove this it shouldn't be needed on the action
      otp: code, // TODO: Remove this it shouldn't be needed on the action
      history,
      realm,
      brand,
      ciamHistory,
      pathBuilder,
    });
  };

  const contentMessageId = getContentMessageId();
  const channelType = channel?.type;
  const otcChannel = channelType === 'phone' || channelType === undefined ? 'mobile-number' : 'email';

  return (
    <>
      {error && <AnalyticsDataLayer eventName={EVENT_OTC_ERROR} error={error} />}
      {!error && header && header.props.id === 'mfa.verifyMobile.enterCode.page.title' && (
        <AnalyticsDataLayer pageName={CONFIRM_YOUR_MOBILE_NUMBER} otcChannel={otcChannel} />
      )}
      <ErrorAlert error={error} />
      <ErrorAlert error={error} errorFor="MFA" />

      {contentMessageId && (
        <FormattedRichMessage
          id={contentMessageId}
          values={{
            new: hasResentOtp ? intl.formatMessage({ id: `mfa.enterCode.new` }) : '',
            channel: intl.formatMessage({ id: `mfa.enterCode.sentByChannel.${channelType}` }),
            oneTimeCode: hasResentOtp ? '' : intl.formatMessage({ id: `mfa.enterCode.content.oneTimeCode` }),
            newOneTimeCode: hasResentOtp ? intl.formatMessage({ id: `mfa.enterCode.content.newOneTimeCode` }) : '',
            maskedValue: channel?.maskedValue,
          }}
        />
      )}

      <P>{content || <FormattedRichMessage id="mfa.enterCode.timeReminder" />}</P>

      <Form onSubmit={handleSubmit}>
        <OneTimeCode
          id="otp-code"
          value={code}
          onChange={handleCodeChange}
          labelId="mfa.enterCode.codeLabel"
          errors={[error]}
          required
          ariaRequired
          autoFocus
          resetValidationMsgOnChange
        />

        <FormElement>
          {/* The Link component internally handles this by rendering it as a button when there is an onClick */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={handleRequestResend}>
            <FormattedMessage id="mfa.enterCode.newCode" />
          </Link>
        </FormElement>

        <FormElement>
          <CoBrandingButton
            id="otp-code-continue"
            customStyle={customStyles.button}
            type="submit"
            disabled={isLoading}
            saving={isLoading}
          >
            <FormattedMessage id="common.continue" />
          </CoBrandingButton>
        </FormElement>
      </Form>

      {!singleChannel && onRequestNewChannel && (
        <FormElement>
          {/* The Link component internally handles this by rendering it as a button when there is an onClick */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={handleRequestNewChannel}>
            <FormattedMessage id="mfa.enterCode.tryAnotherWay" />
          </Link>
        </FormElement>
      )}
    </>
  );
}

MFAEnterCode.propTypes = {
  stage: StageShape.isRequired,
  channel: mfaChannelShape,
  otpCount: PropTypes.number,
  onRequestResend: PropTypes.func.isRequired,
  onRequestNewChannel: PropTypes.func,
  onSubmitCode: PropTypes.func.isRequired,
  error: ErrorShape,
  hasResentOtp: PropTypes.bool,
  singleChannel: PropTypes.bool,
  header: PropTypes.node,
  content: PropTypes.node,
  isLoading: PropTypes.bool,
};

MFAEnterCode.defaultProps = {
  channel: undefined,
  otpCount: 0,
  error: undefined,
  hasResentOtp: false,
  singleChannel: false,
  header: null,
  content: null,
  onRequestNewChannel: null,
  isLoading: false,
};

export default MFAEnterCode;
